.card {
    width: 18rem;
    padding: 0px;
    margin-bottom: 2rem;
}

.cardImg {
    object-fit: cover;
    width: 17.87rem;
    height: 17.87rem;
}

.card-text {
    height: 3rem;
}


.modal-header {
    background-color: rgb(199, 194, 194);
    margin: 0;
    border: 0;
}

.modal-body {
    text-align: center;
    height: fit-content;
}

@media only screen and (max-width: 1000px) {
    .card-text {
        font-size: 1.2rem;
    }
    
    .card {
        width: 23rem;
    }

    .modal-body{
        font-size: 2rem;
    }

    .cardImg {
        width: 22.88rem;
        height: 22.88rem;
    }
}

.card .face.back {
    background-color: #131212;
    color: white;
    height: 345px;
    width: 260px;
    border-radius: 10px;
    height: 360px;
}


#smallText {
    font-size: 15px;
    line-height: 1.5;
}
