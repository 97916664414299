footer {
    display: flex;
    align-items: center;
    width: 100%;
    background-color:  #131212;
    height: 4vw;
}

.footerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #E8E8E8;
    height: fit-content;
    background-color:  #131212;
    padding: 0;
}

.footertext {
    font-size: 1.3rem;
    text-align: center;
}
