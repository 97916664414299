.header {
    text-align: center;
    align-self: center;
    font-size: 4rem;
    font-weight: bold;

    text-transform: uppercase;
	background: linear-gradient(to right, #F52977,#0091AD);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.header_row {
    height: 12rem;
    display:flex;
    justify-content: center;
}

.sponsorRow {
    margin: auto;
    background: linear-gradient(90deg, #F52977,#0091AD);
    text-align: center;
    padding: 50px;
    width: 1250px;
    border-radius: 75px;
    margin-bottom: 75px;
}

h2 {
    text-align: center;
    color: white;
}

h3 {
    margin: 20px !important;
}


img {
    /* object-fit: cover; */
    /* float: left; */
    width: 255px;
    height: 270px;
    border-radius: 10px;
    /* margin: 10px !important; */
    border-radius: 50%;
}

.InserraSponsorImg {
    width: 800px !important;
    height: 200px !important;
    margin: 30px;
    border-radius: 5% !important;
}
.ShackSponsorImg {
    width: 500px !important;
    height: 295px !important;
    margin: 30px;
    border-radius: 5% !important;
}

.bottomSponsorImg {
    width: 550px !important;
    height: 250px !important;
    margin: 20px;
    border-radius: 5% !important;
}

.kumon {
    width: 670px !important;
    height: 250px !important;
    margin: 20px;
    border-radius: 5% !important;
}