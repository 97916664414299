.navbar-color{
    background-color: #131212;
    justify-content: right;
}

.nav-link {
    color: #E8E8E8;
    font-family: sans-serif;
    padding-left: 1%;
    align-content: center;
    text-align: center;
    vertical-align: middle;
}

.navbar {
    width:100vw;
    margin: 0;
}

@media only screen and (max-width: 1000px) {
    .navbar {
        min-height: 7rem;
    }

    #logo {
        display: none;
    }
}

#logo {
    width: 12.5%;
    height: 15%;
}
    

