body {font-family: 'Tilt Neon', sans-serif;}

p {
    color: white;
    font-size: 30px;
}

.text_and_image{
    display: flex;
}

.slideshow {

    display: flex;
    justify-content: center;
    align-items: center !important;
    /* margin-left: 0px !important; */
}

.header_row {
    height: 12rem;
    display:flex;
    justify-content: center;
}

.flashimage, .flash_image2 {
    width: 100%;
}

.rounded-5 {
    min-width: 250px;
}

.header {
    text-align: center;
    align-self: center;
    font-size: 4rem;
    font-weight: bold;

    text-transform: uppercase;
	background: linear-gradient(to right, #F52977,#0091AD);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.grid {
    display: grid;
    width: 80%;
    grid-template-rows: repeat(3, 35%);
    grid-template-columns: repeat(3, 40%);
    align-items: center;
    align-self: center;
    padding-bottom: 2.5rem;
    height: 20rem;
    padding-top: 2.5rem;
}

.fl1 { 
    grid-column: 2 / span 2;
    grid-row: 1 / span 2;
}

.fl2 { 
    grid-column: 1 / span 2;
    grid-row: 2 / span 2;
}

.fl3 { 
    grid-column: 2 / span 2;
    grid-row: 2 / span 2;
}

.fl4 { 
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
}

.question{
    font-size: 225%;
    text-align: center;
    font-weight: bold;
    align-self: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

#answer {
    align-self: center;
    text-align: center;
    font-size: 1.85rem;
    /* max-height: 15vh; */
    padding: 35px;
    /* padding-bottom: 40rem; */
    height: 750px !important;
}

#answ2 {
    padding-bottom: 20rem !important;
}

.text_col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 2.25rem;

    /* background: linear-gradient(90deg, #F52977,#0091AD);
    text-align: center;
    padding: 15px;
    border-radius: 75px;
    margin-bottom: 75px;
    height: 100px; */

}

.gradientBg {
    background: linear-gradient(90deg, #F52977,#0091AD);
    text-align: center;
    padding: 15px !important;
    border-radius: 75px;
    margin-bottom: 75px;
    /* height: 450px;
    margin: 45px; */
}

.gradientBg1 {
    background: linear-gradient(90deg, #F52977,#E462BB);
    text-align: center;
    padding: 45px !important;
    border-radius: 75px;
    margin-bottom: 75px;
    /* height: 450px;
    margin: 45px; */
}

.gradientBg2 {
    background: linear-gradient(90deg, #E462BB,#6A96D6);
    text-align: center;
    padding: 45px !important;
    border-radius: 75px;
    margin-bottom: 75px;
    height: 350px !important;
    margin: 45px;
}

.gradientBg3 {
    background: linear-gradient(90deg, #6A96D6,#0091AD);
    text-align: center;
    padding: 15px !important;
    border-radius: 75px;
    margin-bottom: 75px;
    /* height: 450px;
    margin: 45px; */
}






.image_col {
    padding-top: 1.5rem;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 5rem;
    padding-bottom: 10rem;
}

#col3 {
    height: 35rem !important;
    padding: 0 !important;
}

@media only screen and (min-width: 1001px) {
    #col1 {
        height: 40rem !important;
    }
    
    #row1 {
        height: 40rem !important;
    }

}

@media only screen and (max-width: 1001px) {
    #answ3 {
        padding-right: 0 !important;
    }
}

#answ3 {
    padding-bottom: 20rem !important;
}

.imgContainer {
    width: 100%;
}

/* .registerButton {
    display:flex;
    width: 10rem !important;
    height: fit-content;
    justify-self: center;
    text-align: center;
    font-size: 1.2rem !important;
    background-color: #af1b1b !important;
    border: #af1b1b !important;
    align-items: center;
} */

.registerButton {
    background-color: #d39aff;
    border-radius: 10px;
    transition: .35s all;
    margin: 1px;
    width: 50px !important;
    padding: 5px;
}

.registerButton:hover {
    cursor: pointer;
    background-color: #9d6dc1;
}

.sponsors-row {
    display: flex;
    align-content: center;
    justify-content: center;
}
.sponsors {
    display: flex;
    align-content: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    font-size: 4rem;
}

.sponsors-img {
    width: 80%;
    display: flex;
    align-content: center;
    justify-content: center;
}