/* body {font-family: 'Tilt Neon', sans-serif;} */


.scheduleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
.scheduleImg {
    width: 1000px !important;
    height: 1300px !important;
    margin: 30px;
}