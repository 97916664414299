
.homeCard {
    position: relative;
    height: 450px !important;
    width: 360px !important;
    box-shadow: 0px 0px;
    margin : 15px;
    text-align: center;
    border-radius: 10px;
    transition: .35s all;
    transform-style: preserve-3d;
}

.homeCard:hover {
    box-shadow: 15px 15px #2a2727;
    transform: translateY(-15px);
}

button {
    background-color: #d39aff;
    border-radius: 10px;
    transition: .35s all;
    font-size: 18px;
}

button:hover {
    cursor: pointer;
    background-color: #9d6dc1;
}

.homeCard .face.front {
    background-color: white;
    color: black;
    border-radius: 10px;
    text-align: center !important;
    padding: 25px;
}

.homeCard .face {
    position: absolute;
    height: 100%;
    width: 100%;
}

.q {
    color: #8f63b0;
    margin-top: 0px !important;
    font-size: 25px;
    font-weight: bold;
}

h6 {
    padding: 15px;
}

.noBtns {
    font-size: 18px;
}

.logo {
    height: 200px !important;
    width: 300px !important;
}