#bottomMargin {
    margin-bottom: 150px !important;
}


.classRowHeader {
    height:5rem;
    font-size: 3.5rem;
    text-align: center;
    font-family: 'Tilt Neon', sans-serif;
    padding-top: 1rem;
    margin-bottom: 2rem;

    text-transform: uppercase;
	background: linear-gradient(to right, #F52977,#0091AD);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.classRow {
    margin: auto;
    background: linear-gradient(90deg, #F52977,#0091AD);
    text-align: center;
    padding: 50px;
    width: 1250px;
    border-radius: 75px;
    margin-bottom: 75px;
}




.classesContainer {
    padding-left:4rem;
    padding-right:4rem;
}

.cardRow {
    display:flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
}

.firstInRow {
    display:none;
}

.lastInRow2 {
    display:none;
}


@media only screen and (max-width: 1530px) {
    footer {
        height: 4vh !important;
    }

    .lastInRow {
        display: none;
    }
    .firstInRow {
        display: block;
    }

    .lastInRow2 {
        display: block;
    }
    .firstInRow2 {
        display: none;
    }
}